export function HotelLogo({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 442.62 127.78">
      <g>
        <g>
          <path
            fill="#c3a572"
            d="M334.38,16.78,320.31,52.72a8.5,8.5,0,0,0-.77,3c0,1.55,1.12,2.59,3.52,3.51H304.89c3.51-1.54,4.53-2.47,5.81-5.39L334.38.35l23.7,53.47c1.27,2.92,2.29,3.85,5.81,5.39H345.72c2.4-.92,3.52-2,3.52-3.51a8,8,0,0,0-.79-3Z"
          />
          <path
            fill="#c3a572"
            d="M32.46,6.17c-13.37,0-22,9.33-22,23.47s8.29,23.67,21.83,23.67a23.16,23.16,0,0,0,18.58-8.7l-1,11.84C41,59.07,37,59.85,30.74,59.85,11.67,59.85,0,46.54,0,30.08,0,12.93,12.86.52,30.66.52c7.85,0,12.82,1.36,15.07,1.36C47.17,1.88,48,1.47,49,0V13.7S44.9,6.17,32.46,6.17"
          />
          <path
            fill="#c3a572"
            d="M102.77,53.4c-10.52,0-18.65-10.35-18.65-23.78s8.21-23,19.33-23c11.3,0,18.67,9.14,18.67,23.29,0,13.78-8.07,23.52-19.35,23.52M103.13.5c-17,0-28.61,11.91-28.61,29.22,0,17.11,12.43,30.13,28.61,30.13s28.49-13,28.49-30.13C131.62,12.41,120,.5,103.13.5"
          />
          <path
            fill="#c3a572"
            d="M200.88,42.12V11.3c0-6.15-.6-7.18-5-8.62h17c-4,1.88-4.22,2.58-4.22,8.75V60.36L165,18.89v32c0,5.57.68,6.61,5.39,8.33H152.25c3.48-1.26,4.84-2.76,4.84-8.25V.45Z"
          />
          <path
            fill="#c3a572"
            d="M277.16,16.62c0,11.78-10.23,13.68-10.23,13.68l11,13.33c4.92,6.17,10,12.47,14.87,15.56H280.56L256.45,29.58c5.83-1.2,11.22-4.7,11.22-11.4,0-5.32-4.28-9.63-10.68-9.63a20.77,20.77,0,0,0-5.41.68v38c0,3.95,0,10.46,5.29,12l-1.44,0H239.71l-1.39,0c5.53-1.54,5.14-8.14,5.14-12V14.93c0-3.95.67-10.62-4.25-12.07h20.1c7.51,0,17.85,2.81,17.85,13.76"
          />
          <path
            fill="#c3a572"
            d="M401.38,52.62h-4.21c-3.51,0-4.7-1.37-4.7-5.4V8.39h8.13c14.13,0,22.78,9.36,22.78,22.89,0,13.26-9.08,21.34-22,21.34M433.5,31.07c0-19-14.25-28.22-32.82-28.22H379.41c4.81,1.7,5,2.93,5,8.41V51c0,5.5-1.25,6.95-5,8.22h18.23c22.06,0,35.86-10,35.86-28.14"
          />
          <path
            fill="#c3a572"
            d="M165.06,110.37c0-5.64,0-6.68-.08-7.87s-.37-1.85-1.6-2.11a7,7,0,0,0-1.44-.12c-.41,0-.63-.07-.63-.33s.26-.33.81-.33c2,0,4.31.11,5,.11,1.82,0,3.27-.11,4.53-.11,6.72,0,7.91,3.6,7.91,5.57,0,3-1.75,4.86-3.79,6.49,3.05,1,6.76,3.68,6.76,8,0,4-3.08,8.09-9.91,8.09-.9,0-4.27-.22-5.39-.22-.85,0-3.19.11-4.68.11-.48,0-.74-.11-.74-.37s.15-.3.6-.3a5.67,5.67,0,0,0,1.33-.15c.75-.14.93-1,1.08-2,.19-1.56.19-4.49.19-7.9Zm4.45.63c0,.3.11.37.26.41a8.58,8.58,0,0,0,1.74.11,3.82,3.82,0,0,0,2.68-.7,4.87,4.87,0,0,0,1.52-3.94c0-2.48-1.45-5.75-4.64-5.75a6.47,6.47,0,0,0-1.23.07c-.26.08-.33.23-.33.63Zm4.9,3a5.42,5.42,0,0,0-3.08-.89c-.67,0-1,0-1.6,0-.15,0-.22.07-.22.26v8.79c0,2.45.11,3.08,1.3,3.57a6.65,6.65,0,0,0,2.71.44c1.67,0,4.49-.89,4.49-4.75A8.85,8.85,0,0,0,174.41,114Z"
          />
          <path
            fill="#c3a572"
            d="M200.84,118c-.19,0-.26.08-.34.3l-2,5.31a7.29,7.29,0,0,0-.56,2.26c0,.67.34,1.15,1.49,1.15H200c.45,0,.56.08.56.3s-.22.37-.63.37c-1.19,0-2.78-.11-3.93-.11-.41,0-2.45.11-4.39.11-.48,0-.7-.07-.7-.37s.15-.3.44-.3a10.63,10.63,0,0,0,1.16-.07c1.7-.22,2.41-1.49,3.15-3.34L205,100.27c.44-1.07.67-1.48,1-1.48s.56.33.93,1.19c.89,2,6.83,17.19,9.2,22.83,1.41,3.34,2.49,3.86,3.27,4A7.57,7.57,0,0,0,221,127c.3,0,.48,0,.48.3s-.33.37-1.71.37-4,0-7-.07c-.67,0-1.12,0-1.12-.3s.15-.3.52-.33a.62.62,0,0,0,.34-.86l-3-7.87a.37.37,0,0,0-.37-.26Zm7.57-1.85c.19,0,.22-.11.19-.23l-3.34-9.17c0-.14-.08-.33-.19-.33s-.19.19-.22.33l-3.42,9.14c0,.15,0,.26.15.26Z"
          />
          <path
            fill="#c3a572"
            d="M237.85,116.91c0,4.75,0,7.31.78,8,.63.55,1.6.82,4.49.82,2,0,3.42,0,4.35-1a4.72,4.72,0,0,0,1-2.23c0-.34.11-.56.41-.56s.3.19.3.63a28.83,28.83,0,0,1-.56,4.27c-.22.82-.41,1-2.34,1-5.34,0-7.79-.22-10.8-.22-.86,0-3.19.11-4.68.11-.48,0-.74-.11-.74-.37s.15-.3.59-.3a5.77,5.77,0,0,0,1.34-.15c.74-.14.93-1,1.08-2,.18-1.56.18-4.49.18-7.9v-6.54c0-5.64,0-6.68-.07-7.87s-.37-1.85-1.6-2.11a5.11,5.11,0,0,0-1.22-.12c-.41,0-.64-.07-.64-.33s.26-.33.82-.33c1.75,0,4.08.11,5.09.11s3.6-.11,5-.11c.52,0,.78.07.78.33s-.23.33-.67.33a9.24,9.24,0,0,0-1.45.12c-1,.18-1.3.81-1.37,2.11s-.08,2.23-.08,7.87Z"
          />
          <path
            fill="#c3a572"
            d="M263.54,110.37c0-5.64,0-6.68-.07-7.87s-.45-1.89-1.27-2.08a5.27,5.27,0,0,0-1.33-.15c-.37,0-.6-.07-.6-.37s.3-.29.9-.29c1.41,0,3.75.11,4.82.11s3.12-.11,4.53-.11c.48,0,.78.07.78.29s-.22.37-.59.37a6,6,0,0,0-1.12.12c-1,.18-1.3.81-1.37,2.11s-.07,2.23-.07,7.87v6.54c0,3.6,0,6.53.14,8.13.12,1,.38,1.67,1.49,1.81a14.6,14.6,0,0,0,1.89.15c.41,0,.6.12.6.3s-.3.37-.71.37c-2.45,0-4.79-.11-5.79-.11-.85,0-3.19.11-4.68.11-.48,0-.74-.11-.74-.37s.15-.3.59-.3a5.7,5.7,0,0,0,1.34-.15c.74-.14,1-.77,1.08-1.85.18-1.56.18-4.49.18-8.09Z"
          />
          <path
            fill="#c3a572"
            d="M432.89,5.14A4.82,4.82,0,0,1,437.77.32a4.72,4.72,0,0,1,4.85,4.82,4.8,4.8,0,0,1-4.85,4.8A4.72,4.72,0,0,1,432.89,5.14Zm4.88,4.23a4.07,4.07,0,0,0,4.06-4.24A4.16,4.16,0,0,0,437.77.9a4.06,4.06,0,0,0-4.09,4.23A4.17,4.17,0,0,0,437.77,9.37Zm-.7-3.81v.57c0,1.19.05,1.26.11,1.33s.16.09.46.09a.11.11,0,0,1,.13.1.13.13,0,0,1-.14.11h-.23l-.86,0-.81,0h-.22a.1.1,0,0,1-.11-.1c0-.06.05-.1.13-.1.43,0,.51,0,.53-.38s0-.61,0-1.47V4.47c0-.81,0-1.17,0-1.47s-.2-.37-.58-.38a.1.1,0,0,1-.11-.11c0-.07.07-.09.15-.09h.21l.85,0,.87,0a2.4,2.4,0,0,1,1.53.31,1.09,1.09,0,0,1,.48,1c0,.48-.16.92-.93,1.61.66.76,1.17,1.41,1.56,1.84.23.26.44.43.56.43s.13.05.13.11,0,.09-.15.09h-.3a1.92,1.92,0,0,1-.88-.14,3.61,3.61,0,0,1-1-1c-.28-.38-.56-.83-.7-1Zm0-.49a.93.93,0,0,0,.49.14,1,1,0,0,0,.52-.11c.29-.16.42-.52.42-1a1.12,1.12,0,0,0-1.11-1.26.69.69,0,0,0-.32,0Z"
          />
        </g>
      </g>
    </svg>
  );
}
